<template>
  <div class="content">
    <h1 class="title title--big title--color title--indent">Отчеты</h1>
    <ul class="tabs-list">
      <li class="tabs-list__item">
        <router-link
          :to="{name: 'report-discount'}"
          exact-active-class="tabs-list__link--active"
          class="tabs-list__link"
        >
          Проведенные скидки
        </router-link>
      </li>
      <li class="tabs-list__item">
        <router-link
          :to="{name: 'report-verify'}"
          exact-active-class="tabs-list__link--active"
          class="tabs-list__link"
        >
          Проверка карт
        </router-link>
      </li>
    </ul>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Report',
};
</script>
